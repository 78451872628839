




























































































































import { DefectoEnviar, RecepcionKtb, RecepcionKtbDetalle } from '@/entidades/KTB/RegistroInformacion/RecepcionListadoQuery';
import {Vue,Component, Prop} from 'vue-property-decorator';
import {ConfirmarTablonModal, ConfirmarTablonModalDetalle,SendCalidad} from '@/entidades/KTB/RegistroInformacion/ConfirmarTablon';
import {State,Action} from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import { Pallet ,RecepcionGenerica} from '@/entidades/Calificacion/pallet';
import { Defecto } from '@/entidades/Maestro/Ktb/Defecto';
import { Calificacion } from '@/entidades/MaderaVerde/KtbDespacho';
import { Largo } from '@/entidades/Maestro/Ktb/Largo';
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
@Component({
    components:{
        "tabla" : () =>import('@/components/General/TablaGeneralFinal.vue'),
        'calificar' : () =>import('@/components/KTB/Operacion/Calificar.vue')
    }
})
export default class ConfirmarVolumenTablon extends Vue
{

    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('largo',{namespace: 'maestroKtb'}) listadoLargo!:Array<Largo>;
    @State('espesor',{namespace: 'maestroKtb'}) listadoEspesor!:Array<Espesor>;
    @State('defecto',{namespace:'maestroKtb'}) listadoDefecto!:Array<Defecto>;
    tab=null;
     header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Pallet Visual",sortable:false,value:'palletIdVisual'},
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"Fecha Tumba",sortable:false,value:'fechaTumba'},
            {text:"Largo",sortable:false,value:'largoName'},
            {text:"Espesor",sortable:true,value:'espesorName'},
            {text:"Ancho",sortable:false,value:'ancho'},
            {text:"# Plantilla",sortable:true,value:'numPlantillas'},
            {text:"Volumen",sortable:false,value:'volumen'}                 
    ];
     headerAgrupadoEspesor:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Espesor",sortable:true,value:'espesor'},
            {text:"Volumen",sortable:true,value:'volumen'},
              
    ];
     headerAgrupadoLargo:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Largo",sortable:true,value:'largo'},
            {text:"Volumen",sortable:true,value:'volumen'},
              
    ];

    recepcionGenerica?:RecepcionGenerica;
    disabled: boolean= true;
    modal:boolean = false;
    componenteName:string= "";
    @Prop({type:Object,required:true}) item!:RecepcionKtb;

    listado:Array<RecepcionKtbDetalle> = [];
    defectoEnviar:Array<DefectoEnviar> = [];

    infoEnviar:ConfirmarTablonModal  = new ConfirmarTablonModal();
    abrirModal()
    {
        let pallet:Array<Pallet>=[];
        this.item.items.forEach(t=> {
            pallet.push(new Pallet(t.palletId,t.largoId,t.largoName,t.espesorId,t.espesorName,Math.round(t.volumen),t.pie,t.pulgada,t.palletIdVisual));
        })
        this.recepcionGenerica=new RecepcionGenerica(this.item.id,this.item.volumenCalculo,pallet);
        this.componenteName = "calificar";
        this.modal = true;
    }

    limpiar()
    {
        this.modal = false;
        this.componenteName = "";
    }

    validarInformacion(validar:boolean)
    {
       

        this.item.items.forEach(c=> {
            let volumen = c.volumen - Number(this.infoEnviar.porcentaje) / 100;
            this.infoEnviar.items?.push(new ConfirmarTablonModalDetalle(c.id,c.fechaTumba,Math.round(volumen),c.espesorId,c.largoId,c.numPlantillas,c.ancho,c.largoName));
        });
        this.disabled = !validar;
        this.modal = false;
        this.componenteName = "";
    }

    guardarCalificacion(item:Calificacion,verificador:number)
    {
        this.infoEnviar.calificacion = item;
        this.disabled = false;
        this.limpiar();
    }


    async guardarInfo()
    {
        try
        {
            this.changeLoading(new Loading(true,"Por favor espere...Registrando informacion"));
            let detalle:Array<ConfirmarTablonModalDetalle>=[];
            let volumenTotal:number=0;
            this.item.items.forEach(t=> {
                detalle.push(new ConfirmarTablonModalDetalle(t.id,t.fechaTumba,Math.round(t.volumen),t.espesorId,t.largoId,t.numPlantillas,t.ancho,t.largoName))
                volumenTotal += Math.round(t.volumen);
            })
            this.infoEnviar.items = detalle;
            this.infoEnviar.volumen = volumenTotal;
            this.infoEnviar.procedencia= "TALLY";
            let rsp = await SendCalidad(this.infoEnviar);
            if(rsp.isSuccess){
                this.$emit('limpiar');
                this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",3000));
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {
            //@ts-ignore
                this.changeAlerta(new Alerta(error,true,"danger",3000));
        }
        finally
        {
             this.changeLoading(new Loading(false,""));
        }
    }

    updateVolumen(item:RecepcionKtbDetalle)
    {
        let largo   = this.listadoLargo.find(c=>c.largoId == item.largoId);
        let espesor = this.listadoEspesor.find(c=>c.espesorId == item.espesorId);

        let calculo:number = Math.round(Number(((Number(largo?.pie) * item.ancho * Number(espesor?.pulgada) * Number(item.numPlantillas) * Number(espesor?.factorHuecoTablon)) /12).toFixed(2)));
        item.volumen = calculo;
    }

    get getComponente()
    {
        if(this.componenteName == "calificar")
        {
            return {'items': this.recepcionGenerica, 'listadoDefecto' : this.listadoDefecto,'proceso' : 'ktb'};
        }
        return {'items': this.recepcionGenerica, 'listadoDefecto' : this.listadoDefecto,'proceso' : 'ktb'};
    }

    mounted() 
    {
        this.item.items.forEach(x=>{
            x.volumen = Math.round(x.volumen);
        })
        this.infoEnviar.id = this.item.id;
        this.infoEnviar.usuarioId = this.usuarioId;
        this.infoEnviar.plantaId = this.item.plantaId;
    }
}
