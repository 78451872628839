var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"primary white--text"}},[_c('v-toolbar-title',{staticClass:"mx-auto"},[_vm._v("Confirmar Volumen Tablon")])],1),_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{key:"tab-1"},[_vm._v(" Detalle ")]),_c('v-tab',{key:"tab-2"},[_vm._v(" Agrupado ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"tab-1"},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.item.items},scopedSlots:_vm._u([{key:"item.fechaTumba",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"150px"}},[_c('v-text-field',{model:{value:(item.fechaTumba),callback:function ($$v) {_vm.$set(item, "fechaTumba", $$v)},expression:"item.fechaTumba"}})],1)]}},{key:"item.largoName",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.listadoLargo,"item-text":"descripcion","item-value":"largoId"},on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.largoId),callback:function ($$v) {_vm.$set(item, "largoId", $$v)},expression:"item.largoId"}})]}},{key:"item.espesorName",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.listadoEspesor,"item-text":"descripcion","item-value":"espesorId"},on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.espesorId),callback:function ($$v) {_vm.$set(item, "espesorId", $$v)},expression:"item.espesorId"}})]}},{key:"item.numPlantillas",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.numPlantillas),callback:function ($$v) {_vm.$set(item, "numPlantillas", $$v)},expression:"item.numPlantillas"}})]}},{key:"item.ancho",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{on:{"change":function($event){return _vm.updateVolumen(item)}},model:{value:(item.ancho),callback:function ($$v) {_vm.$set(item, "ancho", $$v)},expression:"item.ancho"}})]}}],null,true)})],1),_c('v-card-text',[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(_vm.componenteName,_vm._b({tag:"component",on:{"limpiar":_vm.limpiar,"calificar":_vm.guardarCalificacion,"guardar":_vm.validarInformacion}},'component',_vm.getComponente,false))],1)],1)],1),_c('v-tab-item',{key:"tab-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headerAgrupadoEspesor,"items":_vm.item.items[0].agrupadoEspesor}})],1),_c('v-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headerAgrupadoLargo,"items":_vm.item.items[0].agrupadoLargo}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.guardarInfo}},[_vm._v("GUARDAR")]),_c('v-btn',{attrs:{"color":"background"},on:{"click":function($event){return _vm.$emit('limpiar')}}},[_vm._v("CANCELAR")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }